<template>
  <div class="file-uploader">
    <div class="queue" :class="{ 'dock-bottom': isDocked }">
      <div v-for="file in filesToUpload" :key="file.id" class="queue-entry">
        <div class="entry-meta">
          <div class="filename">
            <v-clamp autoresize :max-lines="1">{{ file.name }}</v-clamp>
          </div>
          <div class="filesize">{{ file.size | formatNumber }}</div>
        </div>
        <div class="entry-status">
          <div class="upload-control">
            <b-progress
              v-if="!file.success && !file.error"
              size="is-tiny"
              type="is-primary"
              :value="parseInt(file.progress)"
            ></b-progress>
            <div v-if="!file.error" class="percentage">{{ file.progress | formatPercentage }}%</div>
            <div
              v-if="file.error == 'extension' && getExtensions.length"
              class="error-description has-text-danger is-text-tiny"
            >
              {{ $t('user.photoErrFileType') }} {{ getUpperExtensions.join(', ') }}
            </div>
            <div v-else-if="file.error" class="error-description has-text-danger is-text-tiny">
              !!! {{ $t('user.photoErrUpload') }}
            </div>
            <div v-if="file.chunk && !(file.error || file.success)" class="controls">
              <a v-if="file.active" @click="file.chunk.pause()">
                <faicon icon="pause-circle" />
              </a>
              <a v-if="!file.active && file.chunk.hasChunksToUpload" @click="file.chunk.resume()">
                <faicon icon="play-circle" />
              </a>
              <a class="remove-file" @click="destroyUploadingFile(file)">
                <faicon icon="times-circle" />
              </a>
            </div>
            <div v-if="file.error || file.success" class="result">
              <faicon class="is-active" icon="check-circle" />
              <a v-if="!clearAfter" class="remove-file" @click="$refs[referenceId].remove(file)">
                <faicon icon="times-circle" />
              </a>
            </div>
          </div>
          <div v-if="file.active && file.chunk" class="is-text-tiny hidden">
            Uploading {{ file.chunk.chunks.length }} / {{ file.chunk.chunksUploading.length }} /
            {{ file.chunk.chunksUploaded.length }}
          </div>
        </div>
      </div>
    </div>

    <file-upload
      :ref="referenceId"
      v-model="filesToUpload"
      :class="{ 'is-custom': isCustom }"
      chunk-enabled
      :extensions="getExtensions"
      :accept="getAccept"
      :input-id="referenceId"
      :active="true"
      :drop="true"
      :multiple="limit != 1"
      :drop-directory="true"
      :maximum="limit"
      :thread="2"
      :chunk="{
        action: fullPathToUpload,
        minSize: 0,
        headers: { Authorization: $store.getters.getToken },
        maxActive: 2,
        maxRetries: 5,
      }"
      @input-filter="inputFilter"
      @input-file="uploadFile"
    >
      <slot name="navbar">
        <div>
          <span class="upload-button">{{ $t('upload.addFiles') }}</span>
          <span class="drop-label is-hidden-mobile">{{ $t('upload.dropFiles') }}</span>
        </div>
      </slot>
    </file-upload>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import VClamp from 'vue-clamp'

export default {
  components: {
    FileUpload,
    VClamp,
  },
  props: {
    referenceId: String,
    pathToUpload: String,
    allowed: String,
    limit: Number,
    clearAfter: Boolean,
    isDocked: {
      type: Boolean,
      default: false,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filesToUpload: [],
    }
  },
  computed: {
    fullPathToUpload() {
      return process.env.VUE_APP_BASE_API_URI + this.pathToUpload
    },
    getExtensions() {
      const extensionsMap = {
        images: ['gif', 'jpg', 'jpeg', 'png', 'webp'],
        pdf: ['pdf'],
        any: [],
      }
      return extensionsMap[this.allowed]
    },
    getUpperExtensions() {
      const mimetypes = []
      for (const mimetype of this.getExtensions) {
        mimetypes.push(mimetype.toLocaleUpperCase())
      }
      return mimetypes
    },
    getAccept() {
      const acceptMap = {
        images: 'image/png,image/gif,image/jpeg,image/webp',
        pdf: 'application/pdf',
        any: '',
      }
      return acceptMap[this.allowed]
    },
  },
  methods: {
    clear(file) {
      if (this.$refs[this.referenceId]) {
        this.$refs[this.referenceId].remove(file)
      }
    },
    updateParent(file) {
      if (file && file.success) {
        this.$emit('upload', this.filesToUpload)

        if (this.clearAfter) {
          setTimeout(() => {
            this.clear(file)
          }, 5000)
        }
      }
    },
    destroyUploadingFile(file) {
      file.chunk.pause()
      this.clear(file)
    },
    uploadFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        // console.log('add', newFile)
        this.$refs[this.referenceId].active = true
      }
      if (newFile && oldFile) {
        // update
        // console.log('update', newFile)
      }
      if (!newFile && oldFile) {
        // remove
        // console.log('remove', oldFile)
      }
      this.updateParent(newFile)
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
  },
}
</script>
